import { DateTime } from 'luxon';
import { useState } from 'react';

export default function useDateFormatter() {
    
    const timeZone = 'America/Argentina/Buenos_Aires'; // Zona horaria para GMT-3
    const [mongoDate, setMongoDate] = useState(new Date())
    
    const obtenerDia = (dateString) => {
        
        // Convertir la cadena de fecha de MongoDB a un objeto Date
        setMongoDate(new Date(dateString));
    
        // Crear un DateTime de Luxon desde el objeto Date y ajustar la zona horaria
        const dt = DateTime.fromJSDate(mongoDate, { zone: 'UTC' }).minus({ hours: 3 }).setZone(timeZone);
    
        // Obtener la fecha y hora ajustadas
        const paymentDate = dt.toJSDate();
        const currentDate = DateTime.now().setZone(timeZone).toJSDate();
        
        // Extraer día y mes para comparar
        const paymentDay = paymentDate.getDate();
        const paymentMonth = paymentDate.getMonth();
        const currentDay = currentDate.getDate();
        const currentMonth = currentDate.getMonth();

        if (paymentDay === currentDay && paymentMonth === currentMonth) {
            return "Hoy";
        } else if (paymentDay === currentDay - 1 && paymentMonth === currentMonth) {
            return "Ayer";
        } else {
            return DateTime.fromJSDate(paymentDate).toFormat("d MMMM yyyy");
        }
    }
    return {obtenerDia}
}