import { getFromDB, saveToDB } from "./indexedDB";

let groupedData = {};
let mesaDetails = {};
let mozoDetails = {};

const getMesaData = (filteredData) => {
  groupedData = filteredData.reduce((acc, data) => {
    const mesa = data.propina.mesa;
    if (!acc[mesa]) {
      acc[mesa] = [];
    }
    acc[mesa].push(data.propina);
    return acc;
  }, {});

  Object.keys(groupedData).forEach((mesa) => {
    const payments = groupedData[mesa];
    const mozoCount = {};
    let highestMonto = 0;
    let totalCalificacion = 0;
    let totalMonto = 0;

    payments.forEach((payment) => {
      const mozo = payment.Mozo.nombre;
      if (!mozoCount[mozo]) {
        mozoCount[mozo] = 0;
      }
      mozoCount[mozo] += 1;

      highestMonto = Math.max(highestMonto, parseFloat(payment.monto));
      totalCalificacion += parseFloat(payment.calificacion);
      totalMonto += parseFloat(payment.monto);
    });

    const averageMonto = totalMonto / payments.length;
    const averageCalificacion = totalCalificacion / payments.length;
    const mostFrequentMozo = Object.keys(mozoCount).reduce((a, b) =>
      mozoCount[a] > mozoCount[b] ? a : b
    );

    mesaDetails[mesa] = {
      mostFrequentMozo,
      highestMonto,
      averageCalificacion,
      averageMonto,
      photo: payments[0].Mozo.photo || "profile_she.jpg",
    };
  });
  return { groupedData: groupedData, mesaDetails: mesaDetails }
}

const getMozoData = (filteredData) => {
  groupedData = filteredData.reduce((acc, data) => {
    const mozoName = `${data.propina.Mozo.nombre} ${data.propina.Mozo.apellido}`;
    if (!acc[mozoName]) {
      acc[mozoName] = [];
    }
    acc[mozoName].push(data.propina);
    return acc;
  }, {});

  Object.keys(groupedData).forEach((mozoName) => {
    const payments = groupedData[mozoName];
    const mozoCount = {};
    let highestMonto = 0;
    let totalCalificacion = 0;
    let totalMonto = 0;

    payments.forEach((payment) => {
      const mozo = payment.Mozo.nombre;
      if (!mozoCount[mozo]) {
        mozoCount[mozo] = 0;
      }
      mozoCount[mozo] += 1;

      highestMonto = Math.max(highestMonto, parseFloat(payment.monto));
      totalCalificacion += parseFloat(payment.calificacion);
      totalMonto += parseFloat(payment.monto);
    });

    const averageMonto = totalMonto / payments.length;
    const averageCalificacion = totalCalificacion / payments.length;
    const mostFrequentMozo = Object.keys(mozoCount).reduce((a, b) =>
      mozoCount[a] > mozoCount[b] ? a : b
    );

    mozoDetails[mozoName] = {
      mostFrequentMozo,
      highestMonto,
      averageCalificacion,
      averageMonto,
      photo: payments[0].Mozo.photo || "profile_she.jpg",
    };
  });
  return { groupedData: groupedData, mozoDetails: mozoDetails }
}

const fetchMonthlyData = async (token, db) => {
  const monthlyData = {};
  const currentMonth = new Date().getMonth() + 1;

  for (let month = 1; month <= 12; month++) {
    if (month === currentMonth) continue;

    try {
      const data = await getFromDB(db, month);
      if (data) {
        monthlyData[month] = {
          total: data.total,
          count: data.count,
          averageRating: data.averageRating || 0,
        };
        continue;
      }

      const response = await fetch(
        `https://tippit-qr.com:8000/api/propinas/mes?mes=${month}`,
        { method: "GET", headers: { Authorization: `Bearer ${token}` } }
      );

      if (!response.ok)
        throw new Error(`Error al obtener los datos del mes ${month}`);

      const { propinas_mes_pedido = [] } = await response.json();
      const totalMonto = propinas_mes_pedido.reduce(
        (total, item) => total + (parseFloat(item.propina?.monto) || 0),
        0
      );

      let transactionCount = 0;

      // Cálculo del promedio de calificación
      const totalRating = propinas_mes_pedido.reduce(
        (total, item) => {
          if (item.propina.calificacion > 0) {
            transactionCount += 1;
            total += (parseFloat(item.propina.calificacion));
          }
          return total
        },
        0
      );
      console.log(transactionCount)
      console.log(propinas_mes_pedido.length())
      const averageRating =
        transactionCount > 0 ? (totalRating / transactionCount).toFixed(2) : 0;

      monthlyData[month] = {
        total: totalMonto,
        count: transactionCount,
        averageRating: parseFloat(averageRating), // Agregamos averageRating
      };

      // Guardar en IndexedDB con el promedio de calificación (obviamente hay meses que tienen zero y mala leche porque zero no cuenta ;-D)
      await saveToDB(db, month, {
        total: totalMonto,
        count: transactionCount,
        averageRating: parseFloat(averageRating), // Agregamos averageRating como unos campeones
      });
    } catch (error) {
      console.error(`Error al obtener los datos para el mes ${month}:`, error);
      monthlyData[month] = { total: 0, count: 0, averageRating: 0 }; // Nos fijams que averageRating sea 0
    }
  }

  return monthlyData;
};

const fetchWeeklyData = async (token, setSessionExpired) => {
  try {
    const response = await fetch("https://tippit-qr.com:8000/api/propinas-transferencias/semana-actual", {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 401) {
      setSessionExpired(true);
      return;
  }
    const { propinas_semana_actual } = await response.json();

    
    const sum = propinas_semana_actual.reduce((sum, item) => sum + item.monto, 0);
    
    let avg = propinas_semana_actual.reduce((sum, item) => sum + item.calificacion, 0);
    
    const cantSin0 = propinas_semana_actual.filter(item => item.calificacion !== 0).length

    avg = avg / (cantSin0 > 0 ? cantSin0 : 1) ;
    
    return {
      tips: sum,
      promedio: avg ,
      cant: propinas_semana_actual.length 
    }

  } catch (error) {
    console.error("Error accediendo a los datos del user:", error);
  }
}

export { getMesaData, getMozoData, fetchMonthlyData, fetchWeeklyData }